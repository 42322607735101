// セッションごとに大元となるContextを管理する
import { ROOT_CONTEXT } from "@opentelemetry/api";
import type { Context } from "@opentelemetry/api";

let rootCtx: Context;

export const setRootContext = (ctx: Context) => {
  rootCtx = ctx;
};

export const getRootContext = (): Context => {
  return rootCtx;
};

export const clearRootContext = () => {
  rootCtx = ROOT_CONTEXT;
};
