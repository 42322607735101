import classNames from "classnames";
import ReservationPaymentOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOption";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import RequestReservation from "@hooks/useRequestReservation";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  appInstallUrl: string;
};

export const ReservationPaymentOnSite: FC<Props> = ({ appInstallUrl }) => {
  const {
    requestReservation: { pointsToGiveFrame },
  } = RequestReservation.useContainer();
  const pointsOnSiteWeb = pointsToGiveFrame?.webOnSitePayment;
  const pointsOnSystemApp = pointsToGiveFrame?.appOnSystemPayment;

  return (
    <ReservationPaymentOption
      label={"現地でのお支払い"}
      value={StaticPaymentMethodEnum.OnSite}
      pointsToGive={pointsOnSiteWeb ?? undefined}
      errorMessage={null}
      hidesChildrenIfNotSelected
    >
      {pointsOnSiteWeb &&
        pointsOnSystemApp &&
        pointsOnSystemApp > pointsOnSiteWeb && (
          <div className={classNames(styles.appeal)}>
            <div className={styles.appealTitle}>ポイントがもっと貯まる！</div>

            <div className={styles.appealTransformation}>
              <div className={styles.appealTransformationTitle}>
                アプリ利用 & 事前決済で
              </div>
              <div className={styles.appealTransformationPoints}>
                {pointsOnSystemApp}pt
              </div>
            </div>
            <div className={styles.appealLink}>
              <a
                href={appInstallUrl}
                target="_blank"
                className={styles.appealLinkApp}
              >
                アプリをインストールする
              </a>
            </div>
          </div>
        )}
    </ReservationPaymentOption>
  );
};
