import { ApolloClient, from, InMemoryCache, ApolloError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { paths } from "@libs/paths";
import { isBrowser } from "@libs/utils/browser";
import { getIdToken } from "@libs/utils/firebase/auth";
import {
  buildAuthMiddleWare,
  createDebounceLink,
  createErrorLink,
  createHttpLink,
  gtmMiddleware,
  createSpanLink,
} from "./links";
import type {
  NormalizedCacheObject,
  OperationVariables,
  QueryOptions,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import type { GetServerSidePropsResult, Redirect } from "next";
import type { Router } from "next/router";

export const hasStatusCode = (
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
): error is ServerError | ServerParseError => {
  return error && "statusCode" in error;
};

//  eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOfflineError = (error: any): boolean =>
  "networkError" in error && !hasStatusCode(error.networkError);

// Fetch idToken from firebase-auth.
// SEE: [Async Middleware (with ApolloLink) · Issue #2441 · apollographql/apollo-client](https://github.com/apollographql/apollo-client/issues/2441#issuecomment-718502308)
const withToken = setContext(async () => {
  const token = await getIdToken();
  return { token };
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createApolloClient(
  link?: string,
  push?: Router["push"]
): ApolloClient<NormalizedCacheObject> {
  if (!link) {
    link = process.env.API_BASE_URL;
  }

  return new ApolloClient({
    link: from([
      withToken,
      buildAuthMiddleWare(),
      createErrorLink(push),
      createDebounceLink(),
      // place gtmMiddleware after debounceLink
      gtmMiddleware,
      createSpanLink,
      createHttpLink(link),
    ]),
    ssrMode: !isBrowser(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        UsersShopType: {
          keyFields: ["uuid"],
        },
        UsersReservationType: {
          keyFields: ["uuid"],
        },
        UsersLotteryEntryType: {
          keyFields: ["uuid"],
        },
      },
      possibleTypes: {
        // FragmentのネストでUnion/Interfaceを使用する場合はここにサブタイプを明示しないとデータを取得できない
        // SEE: https://github.com/apollographql/apollo-client/issues/7050
      },
    }),
  });
}

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

export type PageProps<T> = {
  data: T;
  // SSR時にフロント側でhydrateするためには一度errorをserializeする必要があるが、
  // 型定義通りにerrorにundefinedを返すとNext.js側でランタイムエラーになるので、nullも許容する。
  error: ApolloError | null;
  [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject;
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

type initializeApolloArg = {
  initialState?: NormalizedCacheObject;
  push?: Router["push"];
  link?: string;
};

export const initializeApollo = ({
  push,
  link,
}: initializeApolloArg): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient = apolloClient ?? createApolloClient(link, push);

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser()) {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export const useApollo = (
  initialState: NormalizedCacheObject
): ApolloClient<NormalizedCacheObject> => {
  const { push } = useRouter();
  return useMemo(
    () =>
      initializeApollo({ initialState, push, link: process.env.API_BASE_URL }),
    [initialState, push]
  );
};

// getServerSideProps(= SSR)向けにGQLクエリを行うための関数。
export const queryForSSP = async <T>(
  options: QueryOptions<OperationVariables, T>
): Promise<GetServerSidePropsResult<PageProps<T>>> => {
  const link = process.env.SSR_API_BASE_URL
    ? process.env.SSR_API_BASE_URL
    : process.env.API_BASE_URL;

  const client = initializeApollo({ link });
  try {
    const { data, error } = await client.query({
      ...options,
      fetchPolicy: "cache-first",
      // errorPolicy = "all | ignore" でないとエラーハンドラのnetworkErrorがnullになるので、強制的に "all" を指定する。
      // SEE: https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
      errorPolicy: "all",
    });
    return {
      props: {
        data,
        error: error ?? null,
        [APOLLO_STATE_PROP_NAME]: client.cache.extract(true),
      },
    };
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      // 異常系
      // 404の場合
      if (hasStatusCode(e.networkError) && e.networkError?.statusCode === 404) {
        return {
          notFound: true,
        };
      }
    }
    return {
      redirect: {
        destination: paths.internalServerError,
        permanent: false,
      },
    };
  }
};

// type guard for getServerSideProps return objects
// SEE: https://nextjs.org/docs/basic-features/data-fetching#getserversideprops-server-side-rendering
type NotFound = Extract<GetServerSidePropsResult<unknown>, { notFound: true }>;
type MyRedirect = Extract<
  GetServerSidePropsResult<unknown>,
  { redirect: Redirect }
>;
type Props<T> = {
  props: PageProps<T>;
};
export const isTypeProps = <T>(
  result:
    | GetServerSidePropsResult<PageProps<T>>
    | Props<T>
    | NotFound
    | MyRedirect
): result is Props<T> => {
  return Object.prototype.hasOwnProperty.call(result, "props");
};
export const isServerError = (
  networkError: unknown
): networkError is ServerError => {
  const serverError = networkError as ServerError;
  return (
    !!serverError.response && !!serverError.result && !!serverError.statusCode
  );
};
