import { ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { trace, SpanKind, propagation, ROOT_CONTEXT } from "@opentelemetry/api";
import DebounceLink from "apollo-link-debounce";
import { getFilteredMatches } from "src/pages/matches";
import { getRootContext } from "@libs/contextManager";
import { paths } from "@libs/paths";
import { isBrowser } from "@libs/utils/browser";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import { hasStatusCode } from ".";
import type {
  ApolloClient_MatchesQuery as MatchesQuery,
  ApolloClient_MatchesQueryVariables as MatchesQueryVariables,
  ApolloClient_ShopsIndexPageQuery as ShopsIndexPageQuery,
  ApolloClient_ShopsIndexPageQueryVariables as ShopsIndexPageQueryVariables,
} from "./__generated__/queries";
import type { ApolloQueryResult } from "@apollo/client";
import type { Router } from "next/router";

export const buildAuthMiddleWare = (): ApolloLink => {
  const authMiddleware = new ApolloLink((op, forward) => {
    op.setContext((ctx: { skipAuth: boolean; token: string | null }) => {
      // Use fetched token of "withToken" middleware.
      const token = ctx.token;
      if (!token || ctx.skipAuth) {
        return {};
      }
      return {
        headers: {
          "X-Fansta-App-Type": "user_web", // API側でUserとOwnerを区別するためにも追加
          Authorization: `Bearer ${token}`,
        },
      };
    });
    return forward(op);
  });

  return authMiddleware;
};

// GraphQLリクエストのSpanを作成し、Traceに追加する
export const createSpanLink = new ApolloLink((operation, forward) => {
  if (typeof window !== "object") {
    return forward(operation);
  }

  const tracer = trace.getTracer("fansta");

  // 20分経過して再描画が走っている場合は、Spanを作成しない
  const rootCtx = getRootContext();
  if (rootCtx === ROOT_CONTEXT) {
    return forward(operation);
  }

  const span = tracer.startSpan(
    `gql.${operation.operationName}`,
    {
      attributes: {},
      kind: SpanKind.INTERNAL,
    },
    rootCtx
  );

  const ctx = trace.setSpan(rootCtx, span);

  const headers = { ...operation.getContext().headers };
  propagation.inject(ctx, headers);
  operation.setContext({ headers });

  return forward(operation).map((data) => {
    // 追加情報としてSpanにtraceIdを追加して終了
    span.setAttribute("traceId", span.spanContext().traceId);
    span.end();

    return data;
  });
});

export const createErrorLink = (push?: Router["push"]): ApolloLink =>
  onError((response) => {
    const { graphQLErrors, networkError } = response;

    console.log("client.onError");
    // 個別のGraphQLのエラー
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (!networkError) {
      return;
    }

    console.log(`[Network error]: ${networkError}`);
    if (!hasStatusCode(networkError)) {
      return;
    }

    // SSR時にrouterを参照するとNext.jsでwarningが出るのでSSR時は無視する。
    if (!push) {
      return;
    }

    if (networkError.statusCode === 404) {
      push(paths.notFound);
    } else if (networkError.statusCode === 500) {
      push(paths.internalServerError);
    }
  });

export const createDebounceLink = () => new DebounceLink(100);

const getMatchesReturned = (
  matchesQuery: ApolloQueryResult<MatchesQuery>
): number | undefined => {
  const {
    data: { matches: unfilteredMatches },
  } = matchesQuery;
  const matches = getFilteredMatches(unfilteredMatches);

  let count = 0;
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    if (match.nodes) {
      count += match.nodes.length;
    }
  }
  return count;
};

// simple wrappers for GTM events in Apollo Links
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createMatchesQueryEvent = (data: any, variables: any): void => {
  const matchesQuery = data as ApolloQueryResult<MatchesQuery>;
  const matchesQueryVariables = variables as MatchesQueryVariables;
  const matchesReturned = getMatchesReturned(matchesQuery);
  const { baseYmd, tournamentIds, teamIds, tournamentIdsTba } =
    matchesQueryVariables;
  GTMEvent(
    GTMEvents.matchesQuery(
      baseYmd ?? undefined,
      tournamentIds ?? undefined,
      teamIds ?? undefined,
      tournamentIdsTba ?? undefined,
      matchesReturned
    )
  );
};
//  eslint-disable-next-line @typescript-eslint/no-explicit-any
const createShopsQueryEvent = (data: any, variables: any): void => {
  const shopsIndexPageQuery = data as ApolloQueryResult<ShopsIndexPageQuery>;
  const shopsIndexPageQueryVariables =
    variables as ShopsIndexPageQueryVariables;
  const {
    areaIds,
    combinedAreaIds,
    matchId,
    teamIds,
    tournamentIds,
    tournamentIdsTba,
  } = shopsIndexPageQueryVariables;
  const {
    data: {
      searchShops: { nodes, totalPage },
    },
  } = shopsIndexPageQuery;
  GTMEvent(
    GTMEvents.shopsQuery(
      areaIds ?? [],
      combinedAreaIds ?? [],
      matchId ? matchId !== 0 : false,
      teamIds ?? [],
      tournamentIds ?? [],
      tournamentIdsTba ?? [],
      nodes.length,
      totalPage
    )
  );
};

export const gtmMiddleware = new ApolloLink((operation, forward) => {
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  return forward(operation).map((data: any) => {
    if (isBrowser()) {
      const { operationName, variables } = operation;
      if (operationName === "Matches") {
        createMatchesQueryEvent(data, variables);
      } else if (operationName === "ShopsIndexPage") {
        createShopsQueryEvent(data, variables);
      }
    }
    return data;
  });
});

// FIXME: bad name because it collides with ApolloClient
export const createHttpLink = (link?: string): HttpLink =>
  new HttpLink({
    uri: link,
  });
