import classNames from "classnames";
import { CreditCardBrandIcon } from "@components/CreditCardBrandIcon";
import ReservationPaymentOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOption";
import type { ReservationPaymentSavedCard_PaymentCardFragment } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentSavedCard/__generated__/fragments";
import RequestReservation from "@hooks/useRequestReservation";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  card: ReservationPaymentSavedCard_PaymentCardFragment;
};

export const ReservationPaymentSavedCard: FC<Props> = ({
  card: { stripePaymentMethodId, brand, last4, isExpired },
}) => {
  const {
    requestReservation: { pointsToGiveFrame },
  } = RequestReservation.useContainer();
  const errorMessage =
    "クレジットカードが無効になっています。マイページのお支払い情報から削除してください。";

  return (
    <ReservationPaymentOption
      label={"クレジットカード"}
      errorMessage={isExpired ? errorMessage : null}
      pointsToGive={pointsToGiveFrame?.webOnSystemPayment ?? undefined}
      value={stripePaymentMethodId}
      hidesChildrenIfNotSelected={false}
    >
      <div className={classNames(styles.registered)}>
        <div className={styles.registeredCard}>
          <CreditCardBrandIcon brand={brand} />
          <div className={styles.registeredCardNumber}>**** {last4}</div>
        </div>
      </div>
    </ReservationPaymentOption>
  );
};
