import { CloudPropagator } from "@google-cloud/opentelemetry-cloud-trace-propagator";
import { propagation, trace, context } from "@opentelemetry/api";
import { CompositePropagator } from "@opentelemetry/core";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";
import { SamplingDecision } from "@opentelemetry/sdk-trace-base";
import {
  SimpleSpanProcessor,
  WebTracerProvider,
  StackContextManager,
} from "@opentelemetry/sdk-trace-web";
import { SEMRESATTRS_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import type { Context, SpanKind, Attributes } from "@opentelemetry/api";
import type { SamplingResult } from "@opentelemetry/sdk-trace-base";
import type { Sampler } from "@opentelemetry/sdk-trace-web";

// WebTraceProviderのregisterInstrumentationsを空に設定すると
// NextJSのDefault Spansが送出されるようになるので、Custom Samplerでフィルタリング
//
// ref: https://nextjs.org/docs/app/building-your-application/optimizing/open-telemetry#default-spans-in-nextjs
class CustomSampler implements Sampler {
  shouldSample(
    _context: Context,
    _traceId: string,
    _spanName: string,
    _spanKind: SpanKind,
    attributes: Attributes
  ): SamplingResult {
    // next.span_typeが存在する場合は、NextJSのDefault Spanなのでサンプリングしない
    if ("next.span_type" in attributes) {
      return {
        decision: SamplingDecision.NOT_RECORD,
      };
    }

    return {
      decision: SamplingDecision.RECORD_AND_SAMPLED,
    };
  }
}

export const initOpentelemetry = () => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: "fansta",
    }),
    sampler: new CustomSampler(),
  });

  // Cloud Traceで利用するために、CloudPropagatorを使ってX-Cloud-Trace-Contextの形式でTraceを伝搬させる
  const propagator = new CompositePropagator({
    propagators: [new CloudPropagator()],
  });
  propagation.setGlobalPropagator(propagator);

  // ZoneContextManagerだとv1/traceにデータを送信する冗長なSpanも取得するので、StackContextManagerを使う
  const contextManager = new StackContextManager();
  contextManager.enable();
  context.setGlobalContextManager(contextManager);

  provider.register({
    contextManager: contextManager,
    propagator: propagator,
  });

  // production環境以外では、Traceを送らない
  if (process.env.DEPLOY_ENV === "production") {
    const exporter = new OTLPTraceExporter({
      url: `${process.env.APP_HOST}/otel_exporter`,
    });
    const processor = new SimpleSpanProcessor(exporter);

    // Opentelemetry Collectorを実行しているPodにTraceを送信する
    provider.addSpanProcessor(processor);
  }

  registerInstrumentations({
    instrumentations: [],
  });

  trace.setGlobalTracerProvider(provider);
};
