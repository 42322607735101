// セッションごとに大元となるSpanを管理する
import type { Span } from "@opentelemetry/api";

let rootSpan: Span | undefined;

export const setRootSpan = (span: Span) => {
  rootSpan = span;
};

export const getRootSpan = (): Span | undefined => {
  return rootSpan;
};
