import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import ReservationPaymentOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOption";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import RequestReservation from "@hooks/useRequestReservation";
import styles from "./index.module.css";
import type {
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import type { FC } from "react";

type Props = {
  handlePaymentElementChange: (event: StripePaymentElementChangeEvent) => void;
  appInstallUrl: string;
};

const paymentElementOptions: StripePaymentElementOptions = {
  fields: {
    billingDetails: { address: { country: "never" } },
  },
};

export const ReservationPaymentNewCard: FC<Props> = ({
  handlePaymentElementChange,
  appInstallUrl,
}) => {
  const elements = useElements();
  const { register, control } = useFormContext<PaymentMethodInputType>();
  const savesNewCard = useWatch({ control, name: "savesNewCard" });
  const {
    requestReservation: { pointsToGiveFrame },
  } = RequestReservation.useContainer();
  const points = pointsToGiveFrame?.webOnSystemPayment;
  const pointsApp = pointsToGiveFrame?.appOnSystemPayment;

  useEffect(() => {
    elements?.update({
      setupFutureUsage: savesNewCard ? "on_session" : null,
    });
  }, [savesNewCard]);

  return (
    <ReservationPaymentOption
      label={"新規クレジットカード"}
      value={StaticPaymentMethodEnum.NewCard}
      pointsToGive={points ?? undefined}
      errorMessage={null}
      hidesChildrenIfNotSelected
    >
      <div className={classNames(styles.register)}>
        <div className={styles.registerFrom}>
          <PaymentElement
            options={paymentElementOptions}
            onChange={handlePaymentElementChange}
          />
        </div>

        {points && pointsApp && pointsApp > points && (
          <div className={classNames(styles.appeal, styles.appealApp)}>
            <div className={styles.appealTitle}>ポイントがもっと貯まる！</div>
            <div className={styles.appealTransformation}>
              <div className={styles.appealTransformationTitle}>
                アプリ利用で
              </div>
              <div className={styles.appealTransformationPercentage}>
                {pointsApp}pt
              </div>
            </div>
            <div className={styles.appealLink}>
              <a
                href={appInstallUrl}
                target="_blank"
                className={styles.appealLinkApp}
              >
                アプリをインストールする
              </a>
            </div>
          </div>
        )}

        <label className={styles.registerCheck}>
          <input type="checkbox" {...register("savesNewCard")} />
          <div className={styles.registerCheckLabels}>
            このカード情報を保存する
          </div>
        </label>
      </div>
    </ReservationPaymentOption>
  );
};
