import FanstaDialog from "@components/FanstaDialog";
import { useReservationPointModal_ReservationPaymentPointConstantsQuery } from "@components/ReservationPointModal/__generated__/queries";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  isShown: boolean;
  hasCloseButton: boolean;
  onCloseButtonClick: () => void;
  onClose: () => void;
  descriptionType: "priceBased" | "personNumBased";
};

export const ReservationPointModal: FC<Props> = ({
  isShown,
  hasCloseButton,
  onCloseButtonClick,
  onClose,
  descriptionType,
}) => {
  const { data } =
    useReservationPointModal_ReservationPaymentPointConstantsQuery();
  if (!data) {
    return null;
  }

  const {
    pointsPerPersonApp,
    pointsPerPersonWeb,
    maximumPointsOnSite,
    maximumPointsOnSystem,
    priceBasedPercentageAppOnSite,
    priceBasedPercentageAppOnSystem,
    priceBasedPercentageWebOnSite,
    priceBasedPercentageWebOnSystem,
  } = data.reservationPaymentPointConstants;
  return (
    <FanstaDialog
      isShown={isShown}
      onBackgroundClick={onClose}
      hasCloseButton={hasCloseButton}
      onCloseButtonClick={onCloseButtonClick}
      closeButtonLayout="icon"
    >
      <div className={styles.modal}>
        <dl>
          <dt className={styles.modalTitle}>獲得ポイント内訳</dt>
          <dd className={styles.modalContents}>
            <div className={styles.point}>
              <div className={styles.pointItem}>
                <div className={styles.pointItemTitle}>WEB予約</div>
                {descriptionType === "priceBased" ? (
                  <>
                    <ul className={styles.pointItemData}>
                      <li>
                        現地決済：決済金額 × {priceBasedPercentageWebOnSite}
                        %(最大{maximumPointsOnSite}pt)
                      </li>
                      <li>
                        事前決済：決済金額 × {priceBasedPercentageWebOnSystem}
                        %(最大{maximumPointsOnSystem}pt)
                      </li>
                    </ul>
                    <div className={styles.pointItemNote}>
                      ※最低獲得ポイントは来店人数 × {pointsPerPersonWeb}ptです。
                    </div>
                  </>
                ) : (
                  <ul className={styles.pointItemData}>
                    <li>
                      来店人数 × {pointsPerPersonWeb}pt(最大
                      {maximumPointsOnSite}pt)
                    </li>
                  </ul>
                )}
              </div>
              <div className={styles.pointItem}>
                <div className={styles.pointItemTitle}>アプリ予約</div>
                {descriptionType === "priceBased" ? (
                  <>
                    <ul className={styles.pointItemData}>
                      <li>
                        現地決済：決済金額 × {priceBasedPercentageAppOnSite}
                        %(最大{maximumPointsOnSite}pt)
                      </li>
                      <li>
                        事前決済：決済金額 × {priceBasedPercentageAppOnSystem}
                        %(最大{maximumPointsOnSystem}pt)
                      </li>
                    </ul>
                    <div className={styles.pointItemNote}>
                      ※最低獲得ポイントは来店人数 × {pointsPerPersonApp}ptです。
                    </div>
                  </>
                ) : (
                  <ul className={styles.pointItemData}>
                    <li>
                      来店人数 × {pointsPerPersonApp}pt(最大
                      {maximumPointsOnSite}pt)
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </FanstaDialog>
  );
};
